<script setup lang="ts">
defineEmits<{
  completed: [completed: boolean]
}>()

const model = defineModel<boolean>()

const uiButton = {
  base: 'flex items-center justify-between',
  color: {
    gray: {
      solid: 'shadow-none bg-forgd-bgd-400 text-forgd-primary-900',
    },
  },
  variant: {
    solid: 'shadow-none bg-forgd-bgd-400 text-forgd-primary-900',
  },
}

function toggle() {
  model.value = !model.value
}
</script>

<template>
  <UButton color="gray" size="md" :ui="uiButton" @click="toggle">
    <span>Mark as complete</span>
    <UToggle :model-value="model" :ui="{ active: 'bg-forgd-primary-900' }" @click.stop="toggle" />
  </UButton>
</template>
