<script setup lang="ts">
const td = useTD()

const actions = [
  { id: 'load-defi-template', label: 'Reload DeFi Template', icon: 'i-heroicons-arrow-path', click: () => td.loadTemplate('defi') },
  { id: 'load-depin-template', label: 'Reload DePin Template', icon: 'i-heroicons-arrow-path', click: () => td.loadTemplate('depin') },
  { id: 'load-base-template', label: 'Reload Base Template', icon: 'i-heroicons-arrow-path', click: () => td.loadTemplate('base') },
  { id: 'onboard-token-designer', label: 'Reset Token Designer', icon: 'i-heroicons-arrow-path', click: () => td.$reset() },
]

const groups = [
  {
    key: 'actions',
    commands: actions,
  },
]

function onSelect(option) {
  if (option.click) {
    option.click()
    isOpen.value = false
  }
}

const isOpen = ref(false)

defineShortcuts({
  meta_k: {
    usingInput: true,
    handler: () => {
      isOpen.value = !isOpen.value
    },
  },
})
</script>

<template>
  <UModal v-model="isOpen">
    <UCommandPalette
      :autoselect="false"
      :groups="groups"
      :searchable="false"
      @update:model-value="onSelect"
    />
  </UModal>
</template>
